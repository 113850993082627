import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";
import "./plugins/chartist";
import "./assets/style.scss";
import i18n from "@/localization";

import http from "@/http";
import auth from "@/auth";

import DefaultLayout from "./layouts/default.vue";
import LoginLayout from "./layouts/login.vue";

Vue.component("default-layout", DefaultLayout);
Vue.component("login-layout", LoginLayout);

import moment from "moment";
Vue.prototype.$moment = moment;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  http,
  auth,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
