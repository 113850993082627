import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "@/store";
import russian from "@/localization/lang/russian";
import english from "@/localization/lang/english";

Vue.use(VueI18n);

export default new VueI18n({
  locale: store.getters.LOCALE,
  fallbackLocale: "ru",
  messages: {
    ru: russian,
    en: english,
  },
});
