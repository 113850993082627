import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawer: false,
    locale: "ru",
    dashboard: {
      charges: {
        total: 0,
        last_week: 0,
        today: 0,
        params: {
          labels: [],
          series: [
            {
              name: "Зарядки",
              data: [],
            },
          ],
        },
      },
      points: {
        params: {
          series: [],
          labels: [],
        },
      },
      status: {
        online: 0,
        offline: 0,
        total_power: 0,
      },
    },
    latest_charges: [],
    show_charge_info: true,
    points: [],
    chargings: [],
    loading_charges: true,
    loading_points: true,
    loading_reports: true,
    vendors_filter: [],
    fw_filter: [],
    total_points: 1,
    total_reports: 1,
    reports: [],
    create_report_dialog: false,
    point_info: {
      address: "",
      available: false,
      city: null,
      created_at: "",
      firmware: null,
      id: 1,
      latitude: 55.76490006524094,
      longitude: 37.66129413574468,
      model: "",
      modem_iccid: null,
      modem_imsi: null,
      name: "",
      password: "",
      point_number: 0,
      reserved: false,
      serial: "",
      updated_at: null,
      vendor: "",
      images: [],
      evse: [{ power: "", connectors: [] }],
    },
    loading_tariffs: false,
    point_tariffs: [],
    point_tariffs_loading: false,
    tariffs: [],
    tariffs_count: 0,
    point_info_loading: false
  },
  mutations: {
    UPDATE_DRAWER(state, payload) {
      state.drawer = payload;
    },
    DASHBOARD_DATA(state, payload) {
      state.dashboard = payload;
    },
    LATEST_DATA(state, payload) {
      state.latest_charges = payload;
    },
    SHOW_CHARGE_INFO(state, payload) {
      state.show_charge_info = payload;
    },
    POINTS_LIST(state, payload) {
      state.points = payload;
    },
    CHARGING_LIST(state, payload) {
      state.chargings = payload;
    },
    LOADING_CHARGES(state, payload) {
      state.loading_charges = payload;
    },
    LOADING_REPORTS(state, payload) {
      state.loading_reports = payload;
    },
    REPORTS_LIST(state, payload) {
      state.reports = payload;
    },
    CREATE_DIALOG(state, payload) {
      state.create_report_dialog = payload;
    },
    POINT_INFO(state, payload) {
      state.point_info = payload.info;
      state.events = payload.events;
      state.commands = payload.commands;
    },
    UPDATE_LOCALE(state, payload) {
      state.locale = payload;
      localStorage.setItem("locale", payload);
    },
    POINT_TARIFFS(state, payload) {
      state.point_tariffs = payload;
    },
    POINT_TARIFFS_LOADING(state, payload) {
      state.point_tariffs_loading = payload;
    },
    UPDATE_TARIFFS(state, payload) {
      state.tariffs_count = Number(payload.headers["total-count"]);
      state.tariffs = payload.data.result;
    },
    LOADING_TARIFFS(state, payload) {
      state.loading_tariffs = payload;
    },
    INFO_LOADING(state, payload) {
      state.point_info_loading = payload;
    },
  },
  actions: {
    update_drawer(store, payload) {
      store.commit("UPDATE_DRAWER", payload);
    },
    dashboard(store) {
      return axios.get("/prt/v1/dashboard").then((response) => {
        store.commit("DASHBOARD_DATA", response.data.result);
      });
    },
    latest_charges(store) {
      return axios.get("/prt/v1/latest").then((response) => {
        store.commit("LATEST_DATA", response.data.result);
      });
    },
    show_charge_info(store, payload) {
      store.commit("SHOW_CHARGE_INFO", payload);
    },
    get_points(store) {
      return axios.get("/prt/v1/points").then((response) => {
        store.commit("POINTS_LIST", response.data.result);
      });
    },
    list_charges(store, options) {
      if (!("page" in options)) return;
      store.commit("LOADING_CHARGES", true);
      const { sortBy, sortDesc, page, itemsPerPage } = options;
      return axios
        .get("/prt/v1/chargings", {
          params: {
            page: page,
            limit: itemsPerPage,
            sortBy: sortBy,
            sortDesc: sortDesc,
          },
        })
        .then((response) => {
          store.commit("CHARGING_LIST", response.data.result);
        })
        .then(() => {
          store.commit("LOADING_CHARGES", false);
        });
    },
    list_reports(store, options) {
      if (!("page" in options)) return;
      store.commit("LOADING_REPORTS", true);
      const { sortBy, sortDesc, page, itemsPerPage, name, repDate } = options;
      return axios
        .get("/prt/v1/reports", {
          params: {
            page: page,
            limit: itemsPerPage,
            sortBy: sortBy,
            sortDesc: sortDesc,
            name: name,
            repDate: repDate,
          },
        })
        .then((response) => {
          store.commit("REPORTS_LIST", response.data.result);
        })
        .then(() => {
          store.commit("LOADING_REPORTS", false);
        });
    },
    open_create_dialog(store) {
      store.commit("CREATE_DIALOG", true);
    },
    create_report(store, params) {
      return axios
        .post("/prt/v1/report/create", params)
        .then(() => {
          store.commit("CREATE_DIALOG", false);
        })
        .then(() => {
          store.dispatch("list_reports", {
            page: 1,
          });
        });
    },
    load_point_info(store, params) {
      localStorage.setItem("point_info", JSON.stringify(params));
      return axios
          .get(`/prt/v1/points/${params.point_id}/info`, {
            params: {
              ...params.query,
            },
          })
          .then((response) => {
            store.commit("POINT_INFO", response.data.result);
          });
    },
    change_locale(store, payload) {
      store.commit("UPDATE_LOCALE", payload);
    },
    load_point_tariffs(store, point_number) {
      store.commit("POINT_TARIFFS_LOADING", true);
      return axios
          .get(`/prt/v1/points/${point_number}/tariffs`)
          .then((response) => {
            store.commit("POINT_TARIFFS", response.data.result);
          })
          .then(() => {
            store.commit("POINT_TARIFFS_LOADING", false);
          });
    },
    load_tariffs(store, options) {
      if (!("page" in options)) return;
      store.commit("LOADING_TARIFFS", true);
      const { sortBy, sortDesc, page, itemsPerPage, query } = options;
      return axios
          .get("/prt/v1/tariffs", {
            params: {
              page: page,
              limit: itemsPerPage,
              sortBy: sortBy,
              sortDesc: sortDesc,
              query: query,
            },
          })
          .then((response) => {
            store.commit("UPDATE_TARIFFS", response);
          })
          .then(() => {
            store.commit("LOADING_TARIFFS", false);
          });
    },
    info_loading(store, payload) {
      store.commit("INFO_LOADING", payload);
    },
    add_point_tariff(store, tariff_id) {
      const point_number = store.getters.POINT_INFO.point_number;
      return axios.put(`/prt/v1/points/${point_number}/tariff/${tariff_id}`);
    },
    delete_point_tariff(store, tariff_id) {
      const point_number = store.getters.POINT_INFO.point_number;
      return axios.delete(`/prt/v1/points/${point_number}/tariff/${tariff_id}`);
    },
  },
  modules: {},
  getters: {
    LOCALE(state) {
      return state.locale;
    },
    DRAWER(state) {
      return state.drawer;
    },
    DASHBOARD(state) {
      return state.dashboard;
    },
    LATEST_CHARGES(state) {
      return state.latest_charges;
    },
    SHOW_CHARGE_INFO(state) {
      return state.show_charge_info;
    },
    POINTS(state) {
      return state.points;
    },
    LOADING_CHARGES(state) {
      return state.loading_charges;
    },
    CHARGINGS(state) {
      return state.chargings;
    },
    LOADING_POINTS(state) {
      return state.loading_points;
    },
    CITY_FILTER(state) {
      return state.city_filter;
    },
    TOTAL_POINTS(state) {
      return state.total_points;
    },
    VENDOR_FILTER(state) {
      return state.vendors_filter;
    },
    FW_FILTER(state) {
      return state.fw_filter;
    },
    REPORTS(state) {
      return state.reports;
    },
    LOADING_REPORTS(state) {
      return state.loading_reports;
    },
    TOTAL_REPORTS(state) {
      return state.total_reports;
    },
    CREATE_DIALOG(state) {
      return state.create_report_dialog;
    },
    POINT_INFO(state) {
      console.log(state.point_info)
      return state.point_info;
    },
    AVAILABLE_EVENTS(state) {
      return state.events;
    },
    AVAILABLE_COMMANDS(state) {
      return state.commands;
    },
    POINT_TARIFFS(state) {
      return state.point_tariffs;
    },
    TOTAL_TARIFFS(state) {
      return state.tariffs_count;
    },
    LOADING_TARIFFS(state) {
      return state.loading_tariffs;
    },
    POINT_TARIFFS_LOADING(state) {
      return state.point_tariffs_loading;
    },
    TARIFFS(state) {
      return state.tariffs;
    },
    INFO_LOADING(state) {
      return state.point_info_loading;
    },
  },
});
