<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn large icon dark v-on="on">
        <v-icon size="30" color="primary">mdi-palette</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list-item>
        <v-list-item-content
        ><v-list-item-title class="font-weight-bold">
          Dark Mode</v-list-item-title
        >
        </v-list-item-content>
        <v-list-item-action
        ><v-switch v-model="$vuetify.theme.dark" @change="changeTheme" />
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-content
        ><v-list-item-title class="font-weight-bold"> {{$t('navBar.locale')}}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action
        ><v-switch
            :value="locale"
            false-value="en"
            true-value="ru"
            @change="changeLocale"
        />
        </v-list-item-action>
      </v-list-item>
      <v-divider />
      <v-card-actions>
        <v-btn text @click="menu = false" color="grey">Закрыть</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "ThemeChanger",
  mounted() {

  },
  data: () => ({
    menu: false,
    themes: [
      {
        name: "Theme 1",
        dark: {
          primary: "#33691E",
          accent: "#FFCA28",
          secondary: "#607D8B",
          success: "#FFEB3B",
          info: "#2196F3",
          warning: "#9C27B0",
          error: "#B71C1C",
        },
        light: {
          primary: "#1976D2",
          accent: "#82B1FF",
          secondary: "#424242",
          success: "#4CAF50",
          info: "#2196F3",
          warning: "#FFC107",
          error: "#FF5252",
        },
      },
      {
        name: "Theme 2",
        dark: {
          primary: "#E65100",
          accent: "#7CB342",
          secondary: "#689F38",
          success: "#4CAF50",
          info: "#6156d8",
          warning: "#1565C0",
          error: "#FF7043",
        },
        light: {
          primary: "#ffa450",
          accent: "#a1e754",
          secondary: "#92de4e",
          success: "#6dff74",
          info: "#7365ff",
          warning: "#2e8ac0",
          error: "#ff5e3c",
        },
      },
      {
        name: "Theme 3",
        dark: {
          primary: "#33691E",
          accent: "#FFCA28",
          secondary: "#607D8B",
          success: "#FFEB3B",
          info: "#2196F3",
          warning: "#9C27B0",
          error: "#B71C1C",
        },
        light: {
          primary: "#6ae240",
          accent: "#ffe063",
          secondary: "#7ea4b6",
          success: "#ffea70",
          info: "#229eff",
          warning: "#e239ff",
          error: "#e82424",
        },
      },
    ],
  }),
  computed: {
    locale() {
      return this.$store.getters.LOCALE;
    },
  },
  methods: {
    changeTheme(value) {
      localStorage.setItem("darkMode", value);
      this.$vuetify.theme.dark = value;
    },
    changeLocale(value) {
      console.log(value)
      this.$store.dispatch("change_locale", value);
      this.$i18n.locale = value;
    },
  },
};
</script>
<style scoped></style>
