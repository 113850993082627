import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";

export const baseUrl =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:9999/"
    : "https://ruscharge.ru";

axios.defaults.baseURL = baseUrl;
Vue.use(VueAxios, axios);

export default {
  root: process.env.VUE_APP_API_URL,
};
