<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
const DEFAULT_LAYOUT = "login";

export default {
  name: "App",
  mounted() {
    if (localStorage.getItem("locale")) {
      const locale = localStorage.getItem("locale");
      this.$store.dispatch("change_locale", locale);
      this.$i18n.locale = locale;
    } else {
      const locale = navigator.language;
      if (locale === "ru-RU") {
        this.$store.dispatch("change_locale", "ru");
        localStorage.setItem("locale", "ru");
        this.$i18n.locale = "ru";
      } else {
        this.$store.dispatch("change_locale", "en");
        localStorage.setItem("locale", "en");
        this.$i18n.locale = "en";
      }
    }
  },
  created() {

  },
  components: {},
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title + " - Партнёрский кабинет" || 'Партнёрский лк';
      }
    },
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || DEFAULT_LAYOUT) + "-layout";
    },
  },
  methods: {},
};
</script>

<style></style>
