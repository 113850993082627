<template>
  <v-navigation-drawer v-model="drawer" app temporary>
    <v-list-item>
      <!--      <v-app-bar-nav-icon-->
      <!--        @click="show_drawer"-->
      <!--        style="width: 48px; height: 48px"-->
      <!--      ></v-app-bar-nav-icon>-->
      <v-list-item-content>
        <v-list-item-title class="text-h6">Partner </v-list-item-title>
        <v-list-item-subtitle> personal cabinet </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list dense nav>
      <template v-for="route in routes">
        <template v-for="subroute in route.children">
          <v-list-item
            v-if="!subroute.hide"
            :key="subroute.name"
            link
            :to="{ name: subroute.name }"
            exact-path
          >
            <v-list-item-icon v-if="subroute.icon">
              <v-icon>{{ subroute.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                  $t(`navBar.${subroute.name}`)
                }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn block @click="logout"> {{ $t("navBar.logout") }} </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "theNavDrawer",
  data: () => {
    return {
      routes: [],
    };
  },
  created() {
    this.$router.options.routes.forEach((route) => {
      if (route.name === "Login") return;
      if (route.hide) return;
      this.routes.push({
        name: route.title ? route.title : route.name,
        path: route.path,
        children: route.children,
      });
    });
  },
  computed: {
    drawer: {
      get() {
        return this.$store.getters.DRAWER;
      },
      set(value) {
        this.$store.dispatch("update_drawer", value);
      },
    },
  },
  methods: {
    show_drawer() {
      this.$store.dispatch("update_drawer", !this.drawer);
    },
    logout() {
      this.$auth.logout();
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="scss">
.v-list-item {
  padding-left: 4px;
}
</style>
