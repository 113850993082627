import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    // dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // primary: colors.purple,
        // secondary: colors.grey.darken1,
        // accent: colors.shades.black,
        // error: colors.red.accent3,
        background: colors.grey.lighten3, // Not automatically applied
      },
      dark: {
        // primary: colors.blue.lighten3,
        // background: colors.grey.darken1, // If not using lighten/darken, use base to return hex
      },
    },
  },
});
