export default {
  service: {
    loading: "Loading... wait",
    search: "Search for %{msg}",
    no_data: "Nothing found",
  },
  headers: {
    id: "ID",
    number: "Number",
    model: "Model",
    vendor: "Vendor",
    serial_number: "Serial number",
    location: "Location",
    firmware: "Firmware",
    updates: "Updates",
    request_id: "Payment ID",
    reason: "Reason",
    client: "Client",
    amount: "Payment amount",
    point: "Charge point",
    connector: "Connector",
    status: "Status",
    real_payment: "Payment by tariff",
    payment_amount: "Payment final",
    message: "Bank response",
    created_at: "Payment date",
    time: "Time",
    date: "Date",
    transferred: "Energy transferred, Wt",
    soc: "Battery charge, %",
    power: "Charge power, kWt",
    start_time: "Start time",
    end_time: "End time",
    card_id: "Card ID",
    card_mask: "Card mask",
    expiration_date_month: "Expiration month",
    expiration_date_year: "Year of expiration",
    card_active: "Active",
    banned: "Blocked",
    send_date: "Sent",
    price: "Price",
    title: "Title",
    start_at: "Effective from",
    end_at: "Effective until",
    created_by: "Added by",
    created_date: "Date created",
    transaction: "Transaction",
    event: "Event",
  },
  payment: {
    approved: "Approved",
    declined: "Rejected",
    created: "Created",
    refunded: "Returned",
    card_add: "Connect card",
    charge_payment: "Charge payment",
  },
  statuses: {
    reserved: "reserved",
    reserved_to: "Reserved",
    charging: "Charging in progress",
    end: "Charging complete",
    paid: "Paid",
    waiting_payment: "Waiting for payment",
    preparing: "Waiting for connection",
    interrupted: "Interrupted/Unpaid",
  },
  charging_info: {
    stop_charge: "End charging",
    payment: "Make payment",
    charge_point: "Station",
    transaction: "Transaction",
    status: "Status",
    duration: "Duration",
    amount: "Amount",
    connector: "Connector",
    transferred: "Energy transferred",
    kvh: "kW",
    reservation: "Reservation to",
    no_reservation: "No reservation",
    reserved: "Reserved",
    preparing: "Preparation",
    charging: "Charging",
    charging_end: "Charging completed",
    paid: "Charging completed, paid",
    waiting_payment: "Charge completed, not paid",
    interrupted: "Interrupted/Unpaid",
  },
  client: {
    client: "Client",
    unban: "Unban",
    ban: "Ban",
    isBanned: "Client blocked",
    fio: "Name",
    name: "First Name",
    last_name: "Last name",
    phone: "Phone",
    email: "Email",
    reg_date: "Registration",
    last_activity: "Last activity",
    last_ip: "Last IP",
    mobile_UUID: "Mobile UUID",
    end_at: "80% completion",
    change: "Change",
    comment: "Client comment",
    edit: "Edit",
    save: "Save",
    cancel: "Cancel",
    client_cards: "Client cards",
    card_block: "Block card",
    card_unblock: "Unblock card",
    client_history: "Charge history",
    more: "More",
    confirmed: "Confirmed",
    not_confirmed: "Not confirmed",
    banned: "Blocked",
    testers: "Testers",
    tester: "Tester",
    active: "Active",
  },
  filters: {
    from: "From",
    to: "To",
    point: "Station",
    status: "Status",
    client: "Client Number",
    payment: "Payment",
  },
  sms: {
    send_sms: "Send sms",
    phone_number: "Phone number",
    close: "Close",
    send: "Send",
  },
  tariff: {
    add_tariff: "Add tariff",
    edit_tariff: "Change tariff",
    delete_warning: "You cannot delete existing tariffs",
    delete_tariff: "Delete tariff",
    delete_charge_point: "Delete station binding",
    number: "Number",
    address: "Address",
    active_points: "Active stations",
    fixed: "Fixed, price per session",
    tariff_price: "Price per tariff",
    start_time: "Start time",
    end_time: "End time",
    tariff_title: "Tariff Title",
    tariff_title_hint: "Title allows to distinguish tariffs",
  },
  points: {
    add: "Add Charge Point",
    number: "Number",
    city: "City",
    vendor: "Vendor",
    firmware: "Firmware",
    list: "List",
    map: "Map",
    test_point: "Test station",
    test_point_info: "The station is only visible to testers",
    point_info: "Station status",
    status_unavailable: "Unavailable",
    status_available: "Available",
    status_repair: "Repair",
    status_emergency: "Emergency button",
    status_reservation: "Reserved",
    status_preparing: "Preparing",
    status_charging: "Charging",
    status_occupied: "Occupied",
    status_charging_paused_evse: "Suspended EVSE",
    status_charging_paused_ev: "Suspended EV",
    status_finishing: "Finishing",
    status_offline: "Offline",
    status_online: "Online",
    command: "Command",
    commands: "Commands",
    event: "Event type",
    mobile_available: "Available in app",
    mobile_unavailable: "Not available in the app",
    change_mobile: "Change visibility",
    edit: "Edit",
    settings: "Customize",
    name: "Name",
    address: "Address",
    serial_number: "Serial number",
    model: "Model",
    max_power: "Power",
    total_energy: "Total transferred",
    last_sync: "Last synchronization",
    connectors: "Connectors",
    date: "Date",
    events_not_found: "No events found",
    commands_not_found: "No commands found",
    more: "More",
    events: "Events",
    con_disabled: "Disabled",
    con_Repair: "Repair",
    con_Charging: "Charging",
    con_Available: "Available",
    con_Preparing: "Preparing",
    con_Finishing: "Finishing",
    con_unavailable: "Unavailable",
    con_Reserved: "Reserved",
    con_Offline: "Offline",
    con_Emergency: "Emergency Button",
    connector: "Connector",
    connector_status: "Connector {con_type} {con_status}",
    connector_error: "Connector {con_type} error",
    point_status: "Point {point_status}",
    error: "Error",
  },
  commands: {
    enable: "Enable {msg}",
    disable: "Disable {msg}",
    start_transaction: "Start charging {transaction} on connector {con_type}",
    stop_transaction: "Stop charging session {transaction}",
    update_transaction: "Update charging session information {transaction}",
    soft_reset: "Soft reboot station",
    hard_reset: "Hard reboot station",
    unlock: "Unlock connector",
    triggerMessage: "Request a message from the station",
    Config: "Station Parameters",
    Update: "Update firmware",
    Status: "Change the status of {con_type} to {status}",
    status_con: "Change {con_type} status",
    status: "Change status",

    enable_command: "Disable/enable {cp_type}",
    cp_type_con: "connector",
    cp_type_point: "station",
    StartTransaction: "Start charging session",
    StopTransaction: "End charging session",
    TransactionInfo: "Update charging session information",
    PointInfo: "Update station information",
    Reset: "Reboot station",
    Unlock_con: "Unlock Connector",
    Unlock: "Unlock station",
    TriggerMessage: "Request a message from the station",
  },
  commands_test: {
    Enable: "Availability",
    StartTransaction: "Start session",
    StopTransaction: "End Session",
    TransactionInfo: "Session Information",
    PointInfo: "Station Information",
    Reset: "Reboot",
    Unlock: "Unlock",
    TriggerMessage: "Request Message",
    Config: "Setup",
    Update: "Update",
    Status: "Status",
  },
  rulesText: {
    required: "Обязательно для заполнения",
    noSpace: "Запрещенный символ",
    min15: "Не менее 15 символов",
    max255: "Не более 255 символов",
    onlyNumber: "Только цифры",
    isIp: "Некорректный IP",
    isMac: "Некорректный MAC",
    minOneDns: "Введите хотя бы один DNS",
    isMask: "Некорректная Маска",
    from_min_to_max: "От {0} до {1}",
  },

  navBar: {
    locale: "Locale",
    index: "Home",
    dashboard: "Dashboard",
    points: "Points",
    users: "Clients",
    billing: "Payments",
    editDevice: "Изменение устройства",
    history: "History",
    point_config: "Charge point configuration",
    tasks: "Tasks",
    tariff: "Tariffs",
    edit_task: "Редактировать",
    logout: "Logout",
    user_create: "Add user",
    point_create: "Add charge station",
    client_create: "Add client",
    list: "List",
    points_list: "Charge points",
    list_users: "Users",
    list_clients: "Clients",
    list_charging: "Charging Sessions",
    list_reports: "Reports",
    list_payment: "Payments",
    list_tariff: "Tariffs",
    list_sms: "SMS",
    tariff_create: "Add tariff",
    tariff_info: "Tariff information",
    clients: "Clients",
    charging: "Charging sessions",
    reports: "Reports",
    report_create: "Charges Report",
    payment: "Payments",
    point_info: "About Station",
    charging_info: "Charging session",
    list_partners: "Partners",
    partners: "Partners",
    client_info: "Client",
    list_rfcard: "Cards",
  },

  loginPage: {
    deviceName: "CP management",
    username: "Username",
    password: "Password",
    error401: "Incorrect username or password",
    login: "Login",
    alert: "Incorrect username or password",
  },

  charts: {
    zoom: "Приближение",
    m_10: "10м",
    h_1: "1ч",
    h_3: "3ч",
    h_6: "6ч",
    h_24: "24ч",
    all: "Всё",
  },
};
