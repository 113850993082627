export default {
  service: {
    loading: "Загрузка... Подождите",
    search: "Поиск по %{msg}",
    no_data: "Ничего не найдено",
  },
  headers: {
    id: "ID",
    number: "Номер",
    model: "Модель",
    vendor: "Производитель",
    serial_number: "Серийный номер",
    location: "Адрес установки",
    firmware: "Прошивка",
    updates: "Обновление",
    request_id: "ID платежа",
    reason: "Причина",
    client: "Клиент",
    amount: "Сумма",
    point: "Станция",
    connector: "Коннектор",
    status: "Статус",
    real_payment: "Оплата по тарифу",
    payment_amount: "Оплата конечная",
    message: "Ответ банка",
    created_at: "Дата платежа",
    time: "Время",
    date: "Дата",
    transferred: "Передано энергии, Вт*ч",
    soc: "Заряд батареи, %",
    power: "Мощность заряда, kWt",
    start_time: "Время начала",
    end_time: "Время окончания",
    card_id: "Идентификатор карты",
    card_mask: "Маска карты",
    expiration_date_month: "Месяц окончания",
    expiration_date_year: "Год окончания",
    card_active: "Активная",
    banned: "Блокировка",
    send_date: "Отправлено",
    price: "Цена",
    title: "Название",
    start_at: "Действует с",
    end_at: "Действует до",
    created_by: "Добавил",
    created_date: "Дата создания",
    transaction: "Номер зарядки",
    event: "Событие",
  },
  payment: {
    approved: "Одобрено",
    declined: "Отклонен",
    created: "Создан",
    refunded: "Возвращен",
    card_add: "Подключение карты",
    charge_payment: "Оплата зарядки",
  },
  statuses: {
    reserved: "Бронирование",
    reserved_to: "Забронирована",
    charging: "Идет зарядка",
    end: "Зарядка завершена",
    paid: "Оплачено",
    waiting_payment: "Ожидание оплаты",
    preparing: "Ожидание подключения",
    interrupted: "Прервана/Неоплачена",
  },
  charging_info: {
    stop_charge: "Завершить зарядку",
    payment: "Провести оплату",
    charge_point: "Станция",
    transaction: "Транзакция",
    status: "Статус",
    duration: "Продолжительность",
    amount: "Сумма",
    connector: "Коннектор",
    transferred: "Передано энергии",
    kvh: "кВт",
    reservation: "Бронирование до",
    no_reservation: "Без бронирования",
    reserved: "Забронирована",
    preparing: "Подготовка",
    charging: "Зарядка",
    charging_end: "Зарядка завершена",
    paid: "Зарядка завершена, оплачена",
    waiting_payment: "Зарядка завершена, не оплачена",
    interrupted: "Прервана/Неоплачена",
  },
  client: {
    client: "Клиент",
    unban: "Разбанить",
    ban: "Забанить",
    isBanned: "Клиент заблокирован",
    fio: "ФИО",
    name: "Имя",
    last_name: "Фамилия",
    phone: "Телефон",
    email: "Email",
    reg_date: "Регистрация",
    last_activity: "Последняя активность",
    last_ip: "Последний IP",
    mobile_UUID: "Mobile_UUID",
    end_at: "Завершение на 80%",
    change: "Изменить",
    comment: "Комментарий к клиенту",
    edit: "Редактировать",
    save: "Сохранить",
    cancel: "Отменить",
    client_cards: "Карты клиента",
    card_block: "Заблокировать карту",
    card_unblock: "Разблокировать карту",
    client_history: "История зарядок",
    more: "Подробнее",
    confirmed: "Подтвержден",
    not_confirmed: "Не подтвержден",
    banned: "Заблокированы",
    testers: "Тестеры",
    tester: "Тестер",
    active: "Активный",
  },
  filters: {
    from: "От",
    to: "До",
    point: "Станция",
    status: "Статус",
    client: "Номер клиента",
    payment: "Оплата",
  },
  sms: {
    send_sms: "Отправить смс",
    phone_number: "Номер телефона",
    close: "Закрыть",
    send: "Добавить",
  },
  tariff: {
    add_tariff: "Добавить тариф",
    edit_tariff: "Изменить тариф",
    delete_warning: "Нельзя удалять действующие тарифы",
    delete_tariff: "Удалить тариф",
    delete_charge_point: "Удалить привязку к станции",
    number: "Номер",
    address: "Адрес",
    active_points: "Активные станции",
    fixed: "Фиксированный, цена за сессию",
    tariff_price: "Цена по тарифу",
    start_time: "Время начала",
    end_time: "Время окончания",
    tariff_title: "Название тарифа",
    tariff_title_hint: "Название позволяют отличать тарифы",
  },
  points: {
    add: "Добавить ЭЗС",
    number: "Номер",
    city: "Город",
    vendor: "Производитель",
    firmware: "Прошивка",
    list: "Список",
    map: "Карта",
    test_point: "Тестовая станция",
    test_point_info: "Станция видна только тестерам",
    point_info: "Статус станции",
    status_unavailable: "Не доступна",
    status_available: "Доступна",
    status_repair: "Ремонт",
    status_emergency: "Аварийная кнопка",
    status_reservation: "Забронирована",
    status_preparing: "Подготовка",
    status_charging: "Зарядка",
    status_occupied: "Занята",
    status_charging_paused_evse: "Зарядка приостановлена станцией",
    status_charging_paused_ev: "Зарядка приостановлена машиной",
    status_finishing: "Окончание зарядки",
    status_offline: "Не в сети",
    status_online: "В сети",
    command: "Команда",
    commands: "Команды",
    event: "Тип события",
    mobile_available: "Доступна в приложении",
    mobile_unavailable: "Недоступна в приложении",
    change_mobile: "Изменить видимость",
    edit: "Редактировать",
    settings: "Настройка",
    name: "Название",
    address: "Адрес",
    serial_number: "Серийный номер",
    model: "Модель",
    max_power: "Мощность",
    total_energy: "Всего передано",
    last_sync: "Последняя синхронизация",
    connectors: "Коннекторы",
    date: "Дата",
    events_not_found: "События не найдены",
    commands_not_found: "Команды не найдены",
    more: "Подробнее",
    events: "События",
    con_disabled: "Отключен",
    con_unavailable: "Недоступен",
    con_Repair: "Ремонт",
    con_Charging: "Зарядка",
    con_Available: "Доступен",
    con_Finishing: "Завершение зарядки",
    con_Preparing: "Подготовка",
    con_Reserved: "Забронирован",
    con_Offline: "Не в сети",
    con_Emergency: "Аварийная кнопка",
    connector: "Коннектор",
    connector_status: "Коннектор {con_type} {con_status}",
    connector_error: "Ошибка коннектора {con_type}",
    point_status: "Станция {point_status}",
    error: "Ошибка",
  },
  commands: {
    enable: "Включить {msg}",
    disable: "Выключить {msg}",
    start_transaction:
      "Запустить зарядку {transaction} по коннектору {con_type}",
    stop_transaction: "Остановить зарядную сессию {transaction}",
    update_transaction: "Обновить информацию о зарядной сессии {transaction}",
    soft_reset: "Мягкая перезагрузка станции",
    hard_reset: "Жесткая перезагрузка станции",
    unlock: "Разблокировать коннектор",
    triggerMessage: "Запросить сообщение от станции",
    Config: "Параметры станции",
    Update: "Обновить прошивку",
    Status: "Изменить статус {con_type} на {status}",
    status_con: "Изменить статус {con_type}",
    status: "Изменить статус",

    enable_command: "Выключить/включить {cp_type}",
    cp_type_con: "коннектор",
    cp_type_point: "станцию",
    StartTransaction: "Началь зарядную сессию",
    StopTransaction: "Завершить зарядную сессию",
    TransactionInfo: "Обновить информацию о зарядной сессии",
    PointInfo: "Обновить информацию о станции",
    Reset: "Перезагрузить станцию",
    Unlock_con: "Разблокировать коннектор",
    Unlock: "Разблокировать станцию",
    TriggerMessage: "Запросить сообщение от станции",
  },
  commands_text: {
    Enable: "Доступность",
    StartTransaction: "Начать сессию",
    StopTransaction: "Завершить сессию",
    TransactionInfo: "Информация о сессии",
    PointInfo: "Информация о станции",
    Reset: "Перезагрузка",
    Unlock: "Разблокировка",
    TriggerMessage: "Запросить сообщение",
    Config: "Настройка",
    Update: "Обновление",
    Status: "Статус",
  },
  rulesText: {
    required: "Обязательно для заполнения",
    noSpace: "Запрещенный символ",
    min15: "Не менее 15 символов",
    max255: "Не более 255 символов",
    onlyNumber: "Только цифры",
    isIp: "Некорректный IP",
    isMac: "Некорректный MAC",
    minOneDns: "Введите хотя бы один DNS",
    isMask: "Некорректная Маска",
    from_min_to_max: "От {0} до {1}",
  },

  navBar: {
    locale: "Язык",
    index: "Главная",
    dashboard: "Дашбоард",
    points: "ЭЗС",
    users: "Пользователи",
    billing: "Платежи",
    editDevice: "Изменение устройства",
    history: "История",
    point_config: "Конфигурация заправки",
    tasks: "Задачи",
    tariff: "Тарифы",
    edit_task: "Редактировать",
    logout: "Выход",
    user_create: "Добавить пользователя",
    point_create: "Добавить заправку",
    client_create: "Добавить клиента",
    list: "Список",
    points_list: "ЭЗС",
    list_users: "Пользователи",
    list_clients: "Клиенты",
    list_charging: "Зарядные сессии",
    list_reports: "Отчеты",
    list_payment: "Платежи",
    list_tariff: "Тарифы",
    list_sms: "SMS",
    tariff_create: "Добавить тариф",
    tariff_info: "Информация о тарифе",
    clients: "Клиенты",
    charging: "Зарядки",
    reports: "Отчеты",
    report_create: "Отчет о зарядках",
    payment: "Платежи",
    point_info: "О станции",
    charging_info: "Зарядная сессия",
    list_partners: "Партнеры",
    partners: "Партнеры",
    client_info: "Клиент",
    list_rfcard: "Карты",
  },

  loginPage: {
    deviceName: "CP management",
    username: "Имя пользователя",
    password: "Пароль",
    error401: "Неправильное имя пользователя или пароль",
    login: "Вход",
    alert: "Неправильное имя пользователя или пароль",
  },

  charts: {
    zoom: "Приближение",
    m_10: "10м",
    h_1: "1ч",
    h_3: "3ч",
    h_6: "6ч",
    h_24: "24ч",
    all: "Всё",
  },
};
