<template>
  <v-app
    id="inspire"
  >
    <v-main>
      <v-container fluid fill-height class="pa-0">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "login",
  mounted() {
    this.$auth
      .fetch()
      .then(() => {
        // this.$router.push("/dashboard");
      })
      .catch(() => {
        this.$auth.logout();
      });
  },
  created() {},
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>

<style scoped></style>
