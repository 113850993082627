import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: {
      layout: "default",
      auth: true,
      title: "dashboard",
    },
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          layout: "default",
          auth: true,
          title: "Dashboard",
        },
      },
    ],
  },
  {
    path: "/points",
    title: "points",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Index.vue"),
    meta: {
      layout: "default",
      auth: true,
    },
    children: [
      {
        path: "",
        name: "points_list",
        component: () => import("../views/Points"),
        meta: {
          auth: true,
          layout: "default",
          title: "Point list",
        },
      },
      {
        path: ":point_id/info",
        name: "point_info",
        hide: true,
        component: () => import("../views/PointInfo"),
        meta: {
          auth: true,
          layout: "default",
          title: "Point info",
        },
      },
      {
        path: ":point_id/config",
        name: "point_config",
        hide: true,
        component: () => import("../views/CPconfig"),
        meta: {
          layout: "default",
          title: "Point config",
        },
      },
    ],
  },
  {
    path: "/chargings",
    title: "charging",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Index.vue"),
    meta: {
      layout: "default",
      auth: true,
    },
    children: [
      {
        path: "",
        name: "list_charging",
        component: () => import("../views/Chargings"),
        meta: {
          auth: true,
          layout: "default",
          title: "Charging list",
        },
      },
      {
        path: ":charging_id/info",
        name: "charging_info",
        hide: true,
        component: () => import("../views/ChargingInfo"),
        meta: {
          auth: true,
          layout: "default",
          title: "Charging info",

        },
      },
    ],
  },
  {
    path: "/reports",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Reports.vue"),
    meta: {
      layout: "default",
      auth: true,
    },
    children: [
      {
        path: "",
        name: "reports",
        component: () => import("@/views/Reports.vue"),
        meta: {
          layout: "default",
          auth: true,
          title: "Reports"
        },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    hide: true,
    component: () => import("../views/Login.vue"),
    meta: {
      layout: "login",
      title: "Вход",
    },
  },
  {
    hide: true,
    path: "*",
    redirect: "/dashboard",
  },
];

Vue.router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default Vue.router;
